@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove duplicate imports to avoid conflicts */
body {
  margin: 0;
  font-family: 'Inter', sans-serif !important; /* Ensure the font is applied globally */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




.antistyle input {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.antistyle input:focus {
  box-shadow: none !important;
  outline: none !important;
}



/* index.css or a global CSS file */
.high-z-index {
  z-index: 10000 !important;
  position: relative !important;
}




@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in-out {
  opacity: 0;
  animation: fade-in-out 1s forwards;
}
